import { ReactNode } from 'react';

import { BasicModal } from './BasicModal';
import Footer from './Footer';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  return (
    <div>
      <main>{children}</main>
      <BasicModal />
      <Footer />
    </div>
  );
};

export default AppWrapper;
